import React from "react";
import "./About.css";
import profile from "../../images/profile.JPG";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { AboutData } from "../../parseJson";
import { MdEmail } from "react-icons/md";

function About() {
  return (
    <section className="main-section container-fluid" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-align-center">
            <div className="heading-container">
              <p className="heading-subtitle">{AboutData.subtitle}</p>
              <h2 className="heading-title">{AboutData.title}</h2>
            </div>
          </div>
        </div>
        <div className=" main-section-content about__info-section">
          <div className="row">
            <div className="col-12 col-lg-5 text-center text-lg-center">
              <img
                className="img-fluid info-profile"
                src={profile}
                alt="profile"
              />
            </div>
            <div className="col-12 col-lg-7 info-container">
              <div className="info-content">
                <h2 className="content-subtitle">
                  {AboutData.content.subtitle}
                </h2>
                <h6 className="content-title">{AboutData.content.title}</h6>
                <div className="content-description">
                  <p>{AboutData.content.description}</p>
                </div>

                <div className="row d-flex justify-content-center">
                  <div className="d-block d-sm-flex align-items-center">
                    <ul className="social-icon-list list-unstyled list-inline">
                      <li className="list-inline-item">
                        <a
                          href={AboutData.content.icon.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i>
                            <FaTwitter />
                          </i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={AboutData.content.icon.github}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i>
                            <FaGithub />
                          </i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={AboutData.content.icon.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i>
                            <FaLinkedin />
                          </i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={AboutData.content.icon.email}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i>
                            <MdEmail />
                          </i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
