import React, { useState } from "react";
import "./Nav.css";
import logoImage from "../../images/profile.JPG";
import { Link } from "react-scroll";
import { FaExternalLinkAlt } from "react-icons/fa";
import { HomeData } from "../../parseJson";

function Nav() {
  const [navbarColor, setNavbarColor] = useState(false);

  const [hashNavList, setHashNavList] = useState([
    { to: "about", name: "About" },
    { url: "https://blog.denilgabani.com", name: "Blog" },
    { to: "skills", name: "Skills" },
    { to: "portfolio", name: "Portfolio" },
    { to: "contactme", name: "Contact" },
  ]);

  const changeNavbar = () => {
    if (window.scrollY >= 500) {
      setNavbarColor(true);
    } else {
      setNavbarColor(false);
    }
  };

  window.addEventListener("scroll", changeNavbar);

  return (
    <nav
      className={
        "navbar navbar-expand-md fixed-top navbar-small " +
        (navbarColor ? "navbar-color-dark" : "navbar-color-transparent")
      }
    >
      <div className="nav-cont">
        <div className="logoContainer">
          <Link
            className="navbar-brand nav-logo"
            to="home"
            smooth={true}
            offset={-70}
            duration={500}
          >
            <img className="logo img-fluid" src={logoImage} alt="logo" />
          </Link>
        </div>

        <button
          className="navbar-toggler ml-auto collapsed"
          data-toggle="collapse"
          data-target="#navBarContent"
          aria-controls="navBarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          role="button"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div
        className="collapse navbar-collapse order-1 order-lg-0"
        id="navBarContent"
      >
        <ul className="myNavList navbar-nav ml-auto">
          {hashNavList.map((data) => {
            return (
              <li className="nav-item">
                {data.to ? (
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to={data.to}
                    smooth={true}
                    spy={true}
                    offset={-70}
                    duration={500}
                  >
                    {data.name}
                  </Link>
                ) : (
                  <a className="nav-link" href={data.url} target="_blank">
                    {data.name}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
