import React from "react";
import Skillcard from "../skillcard/Skillcard";
import { SkillData } from "../../parseJson";
import "./Skills.css";

function Skills() {
  const checkButton = (data) => {
    if (data.button !== undefined) {
      return (
        <div className="btn-div">
          <a href={data.button.link} target="_blank" rel="noopener noreferrer">
            <button
              type="button"
              className="btn btn-outline-primary btn-custom"
            >
              {data.button.title}
            </button>
          </a>
        </div>
      );
    } else return <></>;
  };
  return (
    <div className="main-section container-fluid resume" id="skills">
      <div className="container">
        {/* Skills */}
        <div className="skill-sec">
          <div className="row">
            <div className="col-12">
              <div className="heading-container">
                <p className="heading-subtitle">{SkillData.subtitle}</p>
                <h2 className="heading-title">{SkillData.title}</h2>
              </div>
            </div>
          </div>

          {SkillData.skillcards.map((skillcard) => {
            return (
              <div className="row">
                <div className="sec-single-item col-12">
                  <Skillcard data={skillcard} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Skills;
