import React from "react";
import "./Skillcard.css";

function Skillcard(props) {
  return (
    <div className="skillcard">
      <div className="row">
        <div className="col-12">
          <div className="skillcard-header-container">
            <h6 className="skillcard-title text-center">
              {props.data.skilltitle}
            </h6>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        {props.data.skills.map((skill) => {
          return (
            <div className="skillcard-item col-xs-12 col-sm-6 col-md-3 col-lg-2 text-center">
              <img className="logo-card" src={skill.logo} alt="logo_image" />
              <p className="logo-card-caption">{skill.caption}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Skillcard;
