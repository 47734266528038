import jsonData from "./data.json";

const parsedData = () => {
  return JSON.parse(JSON.stringify(jsonData));
};

const data = parsedData();

export const HomeData = data.home;

export const AboutData = data.about;

export const PortfolioData = data.portfolio;

export const SkillData = data.skill;

export const ContactData = data.contact;

export default data;
