import React from "react";
import {
  FaGithub,
  FaLinkedin,
  FaMailBulk,
  FaMailchimp,
  FaTwitter,
} from "react-icons/fa";
import "./Home.css";
import { HomeData } from "../../parseJson";
import { MdEmail } from "react-icons/md";

function Home() {
  return (
    <section className="section d-flex" id="home">
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-12 col-lg-8 text-center">
            <h1 className="section__subtitle">
              👋🏻 I'm &nbsp;
              <span className="section__subtitle--name"> Denil Gabani</span>
            </h1>
            <h4 className="section__subtitle">
              <span>A Full Stack Developer.</span>
            </h4>
            <ul className="social-icon-list list-unstyled list-inline">
              <li className="list-inline-item">
                <a
                  href={HomeData.icon.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaTwitter />
                  </i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href={HomeData.icon.github}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaGithub />
                  </i>
                </a>
              </li>

              <li className="list-inline-item">
                <a
                  href={HomeData.icon.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaLinkedin />
                  </i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href={HomeData.icon.email}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <MdEmail />
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
