import React from "react";
import "./Card.css";

function Card(props) {
  return (
    <div className="card h-100">
      <img
        src={`/images/${props.data.image}`}
        className="card-image card-img-top"
        alt="card_image"
      />
      <div className="card-body">
        <h5 className="card-title">{props.data.heading}</h5>
        <p className="card-text">{props.data.description}</p>
      </div>
      <div className="card-footer-container ">
        <div className="card-footer d-flex justify-content-around">
          <a
            href={props.data.source}
            target="_blank"
            rel="noopener noreferrer"
            className={props.data.source === "" && "hide-buttons"}
          >
            <button
              type="button"
              className="btn btn-outline-primary btn-custom"
            >
              Source Code
            </button>
          </a>
          <a
            href={props.data.live}
            target="_blank"
            rel="noopener noreferrer"
            className={props.data.live === "" && "hide-buttons"}
          >
            <button
              type="button"
              className="btn btn-outline-primary btn-custom"
            >
              Live Demo
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Card;
